/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.item-divider-styles {
  height: 10px !important;
}

ion-button {
  text-transform: none !important;
}


ion-button {
  font-size: 1.1em;
  min-height: 40px;
}

ion-content {
  --padding-end: 0 !important;
  --padding-start: 0 !important;
}


ion-avatar {
  position: relative;
}

ion-avatar .online {
  position: absolute;
  right: -6px;
  top: 0px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0f0;
}

ion-thumbnail {
  position: relative;
}

ion-thumbnail .online {
  position: absolute;
  right: -6px;
  top: 0px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #0f0;
}

.thumboverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  pointer-events: none;
}


.thumboverlay_text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;

  padding-top: 10px;
  padding-bottom: 20px;
}

.thumboverlay_self {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  pointer-events: none;
}


.thumboverlay_self_text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  pointer-events: none;

  padding-top: 10px;
  padding-bottom: 20px;
}

.atlink {
  color: #ff4;
}

.action-sheet-title {
  color: rgb(255, 73, 97) !important;
}


ion-modal.newPostingModal {
  --height: 450px;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: rgba(0,0,0,0.6)
}

ion-modal.newPostingModal::part(backdrop) {
  background: rgba(0, 0, 0);
  opacity: 1;
}

ion-modal.newPostingModal ion-toolbar {
  /* --background: rgb(14 116 144); */
  --color: white;
}


ion-modal.newCommentModal {
  --height: 300px;
  --width: 90%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: rgba(0,0,0,0.6)
}

ion-modal.newCommentModal::part(backdrop) {
  background: rgba(32,32,32);
  opacity: 1;
}


ion-modal.newCommentModal ion-toolbar {
  /* --background: rgb(14 116 144); */
  --color: white;
}



.widedisplay {
  display: flex;
}

.narrowdisplay {
  display: none;
}

@media screen and (max-width: 340px) {
  .widedisplay {
    display: none;
  }

  .narrowdisplay {
    display: flex;
  }
  
}


.widetext {
  display: flex;
}

.mediumtext {
  display: none;
}

@media screen and (max-width: 400px) {
  .widetext {
    display: none;
  }

  .mediumtext {
    display: flex;
  }
  
}




ion-modal.wmwDetailModal {
  bottom: 57px;

}

ion-modal.wmwDetailModal ion-toolbar {
  /* --background: rgb(14 116 144); */
  --color: white;
}

ion-modal.wmwDetailModal ion-fab-button::part(native) {
 
}